import React from 'react';
import PropTypes from 'prop-types';
import PageTemplate from '../PageTemplate/PageTemplate.js';
import { renderDate } from '../../../../utils/renderingService.js';
import { partnersAssets } from '../../../partnersAssets.js';

export default function FrontPage(props) {
    const { state } = props;
    const { customer, investData } = state;
    const { date } = investData.result;
    const { partnerId } = state.partnerData;

    const parsedDate = date.seconds ? new Date(date.seconds * 1000) : new Date(date);

    const frontPageLogoStyle = partnersAssets.find((partner) => {return partner.partnerId === partnerId})?.investCheckReportFrontPageLogoStyle;
    let frontPageLogo = partnersAssets.find((partner) => {return partner.partnerId === partnerId})?.investCheckReportFrontPageLogo;
    if (frontPageLogo !== "") {
        frontPageLogo = `/assets/img/${partnerId}/${frontPageLogo}`;
    };

    return (
        <PageTemplate
            { ...props }
            alternativeFooter
        >
            <div style={{ textAlign: 'center' }}>
                <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: 'auto', height: '120px', paddingTop: '25px', ...frontPageLogoStyle }} src={frontPageLogo || "/assets/img/monax/logo-dark.svg"} />
                <div style={{ width: 600, marginTop: 120, marginLeft: 'auto', marginRight: 'auto' }}>
                    <p style={{ textTransform: 'upperCase', fontSize: 50, fontWeight: 'bold', display: 'block' }}>
                        InvestTjek
                    </p>
                    <hr />
                    <p style={{ fontSize: 30, display: 'block' }}>{ customer.name }</p>
                    <p style={{ fontSize: 10, display: 'block' }}>Beregnet { renderDate(parsedDate) }</p>
                </div>
            </div>
        </PageTemplate>
    );
}

FrontPage.propTypes = {
    state: PropTypes.object.isRequired,
};
