import React from "react";
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { PersonOutline as PersonOutlineIcon, BarChart as BarChartIcon } from "@mui/icons-material";
import { Link as RouterLink, useLocation } from "react-router-dom";

const SideBarTopContent = () => {
  const { pathname } = useLocation();

  return (
    <div>
      <List disablePadding component="nav" aria-label="sidebar top menu">
        <ListItemButton
          component={RouterLink}
          to="/kunder"
          selected={pathname === "/kunder"}
        >
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Kunder" />
        </ListItemButton>
        <ListItemButton
          component={RouterLink}
          to="/dashboard"
          selected={pathname === "/dashboard"}
        >
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard"/>
        </ListItemButton>

        {/* PLOP_INJECT_PAGE */}
      </List>
    </div>
  );
};

SideBarTopContent.propTypes = {};

export default SideBarTopContent;
