import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import {
  PersonOutline as PersonOutlineIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  ExitToApp as ExitToAppIcon,
} from "@mui/icons-material";
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";
import { logout } from "../../actions/authActions.js";

const SideBarBottomContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const onLogout = useCallback(() => {
    dispatch(logout());
    history.push("/");
  }, [dispatch, history]);

  return (
    <div>
      <List disablePadding component="nav" aria-label="sidebar bottom menu">
        <ListItemButton
          component={RouterLink}
          to="/profil"
          selected={pathname === "/profil"}
        >
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Profil" />
        </ListItemButton>
        <ListItemButton
          component={RouterLink}
          to="/indstillinger"
          selected={pathname === "/indstillinger"}
        >
          <ListItemIcon>
            <SettingsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Indstillinger" />
        </ListItemButton>
        <ListItemButton
          onClick={onLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Log ud" />
        </ListItemButton>
        {/* PLOP_INJECT_PAGE */}
      </List>
      <img
        style={{ marginTop: "16px", marginLeft: "10px" }}
        src={`${process.env.PUBLIC_URL}/assets/img/monax.png`}
      />
    </div>
  );
};

SideBarBottomContent.propTypes = {};

export default SideBarBottomContent;
