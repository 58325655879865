import React from "react";
import PropTypes from "prop-types";
import { renderDate } from "../../../../utils/renderingService.js";
import { imgToBase64 } from "../../../../utils/base64.js";

export function DefaultHeaderImage({ imgStyle, imgData }) {
  return (
    <img
      style={{
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "auto",
        height: "30px",
        ...imgStyle
      }}
      src={imgData}
    />
  );
}

export default async function getDefaultHeader(headerLogoStyle, headerLogo, alternativeHeader = false, date = new Date(),) {
  
  const parsedDate = date.seconds
  ? new Date(date.seconds * 1000)
  : new Date(date);

  const img = await imgToBase64(headerLogo || "/assets/img/monax/logo-dark.svg");

  return (
    <div
      style={{
        width: "100%",
        color: `${alternativeHeader ? "inherit" : "#999999"}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 22.4px",
        fontSize: "8px",
      }}
    >
      <div style={{ flex: "15%", textAlign: "left" }}>
        <p>MiFID Test</p>
      </div>
      <div style={{ flex: "70%" }}>
        <DefaultHeaderImage
          imgStyle={headerLogoStyle}
          imgData={img}
        />
      </div>
      <div style={{ flex: "15%", textAlign: "right" }}>
        {renderDate(parsedDate)}
      </div>
    </div>
  );
}


DefaultHeaderImage.propTypes = {
  imgStyle: PropTypes.object,
  imgData: PropTypes.string.isRequired,
};
