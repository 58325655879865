import React from "react";
import PropTypes from "prop-types";
import { Tabs as MuiTabs, Tab as MuiTab } from "@mui/material";

const Tabs = (props) => {
  const { tabs = [], setValue, value } = props;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <MuiTabs
        sx={{
          "&.MuiTabs-root": {
            borderBottom: "1px solid #eaeaea",
            height: 55,
          },
        }}
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs.map((tab, i) => (
          <MuiTab
            sx={{
              "&.MuiTab-root": {
                color: "secondary.main",
                textTransform: "none",
                minWidth: 105,
                fontWeight: "normal",
                fontSize: "14px",
                marginRight: 4,
              },
              "&.MuiTab-textColorSecondary": {
                opacity: 0.7,
              },
              "&.Mui-selected": {
                opacity: 1,
              },
            }}
            disableRipple
            label={tab.label}
            key={i}
            disabled={tab.disabled}
          />
        ))}
      </MuiTabs>
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array,
  setValue: PropTypes.func,
  value: PropTypes.node,
};

export default Tabs;
