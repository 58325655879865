export const partnersAssets = [
    {
        partnerId: "ml-advice",
        onboardingLogoStyle: {},
        onboardingLogo: "logo-off.svg",
        mifidLogoStyle: {},
        mifidLogo: "logo-dark.svg",
        investCheckReportFrontPageLogoStyle: {},
        investCheckReportFrontPageLogo: "logo-dark.svg",
        reportFooterText: "Missing Link ApS | Studio Nordvest Glasvej 3, 1., 2400 København NV | CVR: 40297979",
        mifidReportHeaderLogoStyle: {},
        mifidReportHeaderLogo: "logo-dark.svg"
    },
    {
        partnerId: "f10",
        onboardingLogoStyle: {},
        onboardingLogo: "logo-2.png",
        mifidLogoStyle: {},
        mifidLogo: "logo-2.png",
        investCheckReportFrontPageLogoStyle: {},
        investCheckReportFrontPageLogo: "logo-2.png",
        reportFooterText: "F10 Finansrådgiver P/S | Hollufgårds Allé 2, 5220 Odense | kontakt@f10.dk | CVR: 35028595",
        mifidReportHeaderLogoStyle: {},
        mifidReportHeaderLogo: "logo-2.png"
    },
    {
        partnerId: "dannebroginvest",
        onboardingLogoStyle: {marginTop: 0, marginLeft: 10, height: '80px'},
        onboardingLogo: "dannebroginvest-logo.png",
        mifidLogoStyle: {},
        mifidLogo: "dannebroginvest-logo.png",
        investCheckReportFrontPageLogoStyle: {},
        investCheckReportFrontPageLogo: "dannebroginvest-logo.png",
        reportFooterText: "Dannebrog Invest | Jernbanegade 23, 4000 Roskilde | CVR: 39763303",
        mifidReportHeaderLogoStyle: {},
        mifidReportHeaderLogo: "dannebroginvest-logo.png"
    },
    {
        partnerId: "arvadfinanshus",
        onboardingLogoStyle: {marginTop: 0, marginLeft: 10, height: '80px'},
        onboardingLogo: "arvadfinanshus-logo-landscape.png",
        mifidLogoStyle: {},
        mifidLogo: "arvadfinanshus-logo.png",
        investCheckReportFrontPageLogoStyle: {},
        investCheckReportFrontPageLogo: "arvadfinanshus-logo.png",
        reportFooterText: "Arvad Finanshus | Emdrupvej 54, 2400 København NV | kontakt@arvad-finanshus.dk | CVR: 36474629",
        mifidReportHeaderLogoStyle: {},
        mifidReportHeaderLogo: "arvadfinanshus-logo.png"
    }
];