import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { v4 as uuidv4 } from 'uuid';
import * as types from './actionTypes.js';
import * as processDataManager from '../databaseRepository/processDataManager.js';
import * as mifidDataManager from '../databaseRepository/mifidDataManager.js';
import { generateAndAddMagicLinkToken, deleteMagicLinkToken } from '../databaseRepository/magicLinkDataManager.js';
import * as reportApiManager from '../apiRepository/reportApiManager.js';
import getDefaultHeader from '../components/Reports/Pages/Headers/MifidDefaultHeader.js';
import DefaultFooter from '../components/Reports/Pages/Footers/DefaultFooter.js';
import { getProcessStatus } from './processActions.js';
import { partnersAssets } from '../components/partnersAssets.js';

export function getMifidProcesses(customerId) {
    return async (dispatch, getState) => {
        const { auth } = getState();
        dispatch({ type: types.MIFID_PROCESSES_LOAD_REQUEST });
        const processes = await mifidDataManager.getAllMifidProccess(auth.user.partnerId, customerId);
        // eslint-disable-next-line no-restricted-syntax
        for (const process of processes) {
            const status = await getProcessStatus(process); // eslint-disable-line no-await-in-loop
            process.status = status;
        }
        dispatch({ type: types.MIFID_PROCESSES_LOADED, processesData: { processes } });
        return Promise.resolve();
    };
}

export function getMifidProcess(partnerId, customerId, processId) {
    return async (dispatch) => {
        dispatch({ type: types.MIFID_PROCESSES_LOAD_REQUEST });
        const process = await mifidDataManager.getMifidProcess(partnerId, customerId, processId);
        dispatch({ type: types.MIFID_PROCESSES_LOADED, processesData: { processes: [ process ] } });
        return Promise.resolve();
    };
}

export function createMifidProcess(processData, mifidDataInput) {
    return async (dispatch, getState) => {
        const { auth } = getState();
        const processId = uuidv4();
        const now = new Date();
        const newProcessData = {
            partnerId: auth.user.partnerId,
            advisorId: auth.user.advisorId,
            deleted: false,
            createdAt: now,
            processId,
            ...processData,
        };

        await processDataManager.updateProcess(newProcessData);
        await mifidDataManager.updateMifidData({ ...mifidDataInput, partnerId: newProcessData.partnerId, customerId: newProcessData.customerId, processId });
        dispatch({ type: types.MIFID_PROCESSES_ADDED, process: { ...newProcessData, mifidData: mifidDataInput } });
        return processId;
    };
}

export function updateMifidData(partnerId, customerId, processId, mifidDataInput) {
    return async (dispatch) => {
        dispatch({ type: types.MIFID_PROCESSES_UPDATING, processId });
        dispatch({ type: types.MIFID_PROCESSES_MIFID_DATA_UPDATED, processId, mifidData: mifidDataInput });
        await mifidDataManager.updateMifidData({ ...mifidDataInput, partnerId, customerId, processId });
        dispatch({ type: types.MIFID_PROCESSES_UPDATED, processId });
        return Promise.resolve();
    };
}

export function updateMifidDataField(processId, fieldName, fieldValue) {
    return async (dispatch, getState) => {
        dispatch({ type: types.MIFID_PROCESSES_UPDATING, processId });
        dispatch({ type: types.MIFID_PROCESSES_MIFID_DATA_FIELD_UPDATED, processId, fieldName, fieldValue });
        const { mifidProcessesData } = getState();
        const { processes } = mifidProcessesData;
        const process = processes.find(x => x.processId === processId);
        await mifidDataManager.updateMifidData({ ...process.mifidData, partnerId: process.partnerId, customerId: process.customerId, processId });
        return dispatch({ type: types.MIFID_PROCESSES_UPDATED, processId });
    };
}

export function updateMifidProcessField(processId, fieldName, fieldValue) {
    return async (dispatch, getState) => {
        dispatch({ type: types.MIFID_PROCESSES_UPDATING, processId });
        dispatch({ type: types.MIFID_PROCESSES_FIELD_UPDATED, processId, fieldName, fieldValue });
        const { mifidProcessesData } = getState();
        const process = mifidProcessesData.processes.find(x => x.processId === processId);
        await mifidDataManager.updateMifidProcess(process);
        return dispatch({ type: types.MIFID_PROCESSES_UPDATED, processId });
    };
}

export function generateCustomerLink(processId, customerId) {
    return async (dispatch, getState) => {
        const { auth } = getState();

        const customerLink = await generateAndAddMagicLinkToken(auth.user.partnerId, customerId, processId, 'mifid-user');

        dispatch({ type: types.MIFID_PROCESSES_UPDATING, processId });
        dispatch({ type: types.MIFID_PROCESSES_FIELD_UPDATED, processId, fieldName: 'customerLink', fieldValue: customerLink });
        const { mifidProcessesData } = getState();
        const process = mifidProcessesData.processes.find(x => x.processId === processId);
        await mifidDataManager.updateMifidProcess(process);
        return dispatch({ type: types.MIFID_PROCESSES_UPDATED, processId });
    };
}

export function deleteCustomerLink(processId) {
    return async (dispatch, getState) => {
        dispatch({ type: types.MIFID_PROCESSES_UPDATING, processId });
        let { mifidProcessesData } = getState();
        let process = mifidProcessesData.processes.find(x => x.processId === processId);
        const magickTokenId = process.customerLink.magicToken;
        dispatch({ type: types.MIFID_PROCESSES_FIELD_UPDATED, processId, fieldName: 'customerLink', fieldValue: null });
        mifidProcessesData = getState().mifidProcessesData;
        process = mifidProcessesData.processes.find(x => x.processId === processId);
        await deleteMagicLinkToken(magickTokenId);
        await mifidDataManager.updateMifidProcess(process);
        return dispatch({ type: types.MIFID_PROCESSES_UPDATED, processId });
    };
}

export function downloadReport(processId, customerId) {
    return async (dispatch, getState) => {
        dispatch({ type: types.MIFID_PROCESSES_UPDATING, processId });
        try {
            const { mifidProcessesData, customerData, partnerData, site } = getState();
            const customer = customerData.customers.find((c) => c.customerId === customerId);
            const process = mifidProcessesData.processes.find(x => x.processId === processId);
            const footerText = partnersAssets.find((partner) => {return partner.partnerId === partnerData.partnerId})?.reportFooterText;
            const footerHtmlString = ReactDOMServer.renderToStaticMarkup(
                <DefaultFooter footerText={footerText} />
            );
            const headerLogoStyle = partnersAssets.find((partner) => {return partner.partnerId === partnerData.partnerId})?.mifidReportHeaderLogoStyle;
            let headerLogo = partnersAssets.find((partner) => {return partner.partnerId === partnerData.partnerId})?.mifidReportHeaderLogo;
            if (headerLogo !== "") {
                headerLogo = `/assets/img/${partnerData.partnerId}/${headerLogo}`;
            };
            const headerHtmlString = ReactDOMServer.renderToStaticMarkup(await getDefaultHeader(headerLogoStyle, headerLogo));
            await reportApiManager.downloadMifidReport(process.mifidData, customer, partnerData, site, footerHtmlString, headerHtmlString);
            return dispatch({ type: types.MIFID_PROCESSES_UPDATED, processId });
        } catch (error) {
            return dispatch({ type: types.MIFID_PROCESSES_UPDATED, processId });
        }
    };
}
