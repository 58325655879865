import React from "react";
import PropTypes from "prop-types";
import DefaultPageTemplate from "./DefaultPageTemplate.js";
import { partnersAssets } from "../../../partnersAssets.js";

function PageTemplate(props) {
  const { state = false } = props;
  if (!state || !state.partnerData) {
    return <DefaultPageTemplate>{props.children}</DefaultPageTemplate>;
  };
  const partnerText = partnersAssets.find((partner) => {return partner.partnerId === state.partnerData.partnerId})?.reportFooterText;
  return <DefaultPageTemplate footerText={partnerText}>{props.children}</DefaultPageTemplate>;
}

PageTemplate.propTypes = {
  state: PropTypes.any,
  children: PropTypes.node.isRequired,
};

export default PageTemplate;
