import initialState from './initialState.js';
import * as types from '../actions/actionTypes.js';

export default function customerEventsDataReducer(state = initialState.customerEventsData, action) {
    switch (action.type) {
        case types.CUSTOMER_EVENTS_DATA_UPDATE_REQUEST: {
            return { ...state, isFetching: true };
        }
        case types.CUSTOMER_EVENTS_DATA_UPDATED: {
            return { ...state, ...action.customerEventsData, isFetching: false };
        }
        case types.CUSTOMER_EVENT_DATA_UPDATED: {
            const { events } = state;
            const index = getIndex(events, action.eventData.eventId);
            const updatedEvent = action.eventData;

            return {
                ...state,
                events: [
                    ...events.slice(0, index),
                    updatedEvent,
                    ...events.slice(index + 1),
                ]
            };
        }
        default:
            return state;
    }
}

function getIndex(events, eventId) {
    return events.findIndex(event => event.eventId === eventId);
};
