import React from "react";
import PropTypes from "prop-types";

class DefaultPageTemplate extends React.Component {
  render() {
    const { children, footerText, alternativeFooter = false } = this.props;
    return (
      <div
        className="page A4-landscape"
        style={{ display: "flex", flexDirection: "column", padding: "10px" }}
      >
        <div style={{ height: "40px" }} />
        <div style={{ flexGrow: 1, margin: "10px 20px" }}>{children}</div>
        <div
          style={{ height: "40px", textAlign: "center", color: `${alternativeFooter ? "inherit" : "#999999"}` }}
        >
          {footerText || "Missing Link ApS | Studio Nordvest Glasvej 3, 1., 2400 København NV | CVR: 40297979"}
        </div>
      </div>
    );
  }
}

DefaultPageTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  footerText: PropTypes.string,
  alternativeFooter: PropTypes.bool,
};

export default DefaultPageTemplate;
